import React from 'react'
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'


function Choose() {
  return (<>


    <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold font-serif tracking-tight text-white sm:text-4xl">ALL-IN-ONE PLATFORM</h2>
            <p className="mt-4 text-2xl leading-8 text-gray-300">
              Join Over 100,000 Businesses that Trust Incpass
            </p>


            <p className="mt-4 text-md leading-8 text-gray-200">
              Your business’s one-stop destination. From business registration to office acquisition and seamless payment management, trust Incpass to empower your company’s success with seamless efficiency.
            </p>

<div className='mt-5'>


<a
                href="https://incpass.ca/start"
                className="rounded-md bg-white my-5 px-3.5 py-2.5 text-xl font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Get started
              </a>


</div>

           





          </div>





          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 bg-warning">
                <CalendarDaysIcon className="h-6 w-6 text-black" aria-hidden="true" />

              </div>
              <dt className="mt-4 font-semibold text-2xl text-white">New Businesses</dt>
              <dd className="mt-2 leading-7 text-gray-300">
                IncPass offers a complete set of tools for seamless business registration.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 bg-success">
                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-2xl text-white">Automated</dt>
              <dd className="mt-2 leading-7 text-gray-300">
                Streamline your entrepreneurial journey with our automated registration system.
              </dd>


              
          
            </div>


           




          </dl>



        </div>
      </div>
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffffff] to-[#9d9d9d] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>


  </>

  )
}

export default Choose