import Books from "../components/Books";

import React from 'react'

function BlogGrid() {
  return (<>
  <div className="App">
  <Books/>

  </div>
  
  </>
  
  )
}

export default BlogGrid