import React from 'react'
import { IoIosArrowForward } from "react-icons/io";


function Features() {

    const features = [
        { name: 'The Right Price', description: 'IncPass ensures precise business setup costs in line with your chosen province, offering transparency for informed decisions.' },
        { name: 'All In One Platform', description: 'Speed up registration with our efficient Rush Incorporation Plan, perfect for those wanting a quick start to business operations.' },
        { name: 'Help & Support', description: 'IncPass simplifies the process—register, manage payments, and conduct unlimited name checks seamlessly on one inclusive platform.' },
        { name: 'Save Time & Money', description: 'Optimize payments with IncPass for confident, hassle-free transactions, locally and internationally.' },
        { name: 'Incpass Perks Memberships', description: 'Incpass provides an affordable remote business setup, ensuring convenience without breaking the bank.' },
        { name: 'Top Quality Legal Documents', description: 'Access all features conveniently through the user-friendly Incpass dashboard—manage your business effortlessly, anytime, anywhere.' },
      ]


    return (<>

        {/* <div class="container text-center boxHeader04 mt-5">

            <span style={{ color: "#5f3dc8" }}>FEATURES</span>
            <h2>Why Choose IncPass?
            </h2>
            <p className='incPara'>Incpass is a trusted platform for entrepreneurs and businesses seeking a smooth and efficient incorporation experience across all Canadian provinces.

            </p>


            <div class="row row-cols-1 row-cols-md-3 g-4 pt-4">
                <div class="col featureBox">
                    <div class="card h-100 h100 h105 ">
                        <img src="/img/d2.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}>IncPass ensures precise business setup costs in line with your chosen province, offering transparency for informed decisions.


                            </p>
                        </div>

                    </div>
                </div>
                <div class="col featureBox">
                    <div class="card h-100 h100 h105">
                        <img src="/img/d3.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}>Speed up registration with our efficient Rush Incorporation Plan, perfect for those wanting a quick start to business operations.
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col featureBox">
                    <div class="card h-100 h100 h105">
                        <img src="/img/d4.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}> IncPass simplifies the process—register, manage payments, and conduct unlimited name checks seamlessly on one inclusive platform.

                            </p>
                        </div>

                    </div>
                </div>










            </div>


            <div class="row row-cols-1 row-cols-md-3 g-4 pt-4">
                <div class="col featureBox">
                    <div class="card h-100 h100 h105">
                        <img src="/img/d5.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}>Optimize payments with IncPass for confident, hassle-free transactions, locally and internationally.
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col featureBox ">
                    <div class="card h-100 h100 h105">
                        <img src="/img/d6.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}>Incpass provides an affordable remote business setup, ensuring convenience without breaking the bank.
                            </p>
                        </div>

                    </div>
                </div>
                <div class="col featureBox">
                    <div class="card h-100 h100 h105">
                        <img src="/img/d7.png" class="card-img-top" alt="..." className='featureimg' />
                        <div class="card-body">

                            <p className="card-text" style={{ textAlign: 'start' }}>Access all features conveniently through the user-friendly Incpass dashboard—manage your business effortlessly, anytime, anywhere.
                            </p>
                        </div>

                    </div>
                </div>










            </div>




        </div> */}





<div className="relative isolate overflow-hidden bg-gray-100 py-8 sm:py-8 lg:py-10">


<div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
          <h2 className="text-5xl font-bold font-serif tracking-tight text-gray-900 sm:text-4xl">Why Choose IncPass?</h2>
          <p className="mt-4 text-gray-600">
          Incpass is a trusted platform for entrepreneurs and businesses seeking a smooth and efficient incorporation experience across all Canadian provinces.


          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-bold text-xl text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-md text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
          <img
            src="/img/group.png"
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
          />
         
        </div>
      </div>
    </div>



    <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[30.1875rem] bg-gradient-to-tr from-[#131612] to-[#1d1f1e] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      </div>















    </>

    )
}

export default Features