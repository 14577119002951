import React from 'react'
import "../tail.css";


function Testimonial() {
  return (<>
  
  <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.green.500),white)] opacity-20"/>
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <h2 className='justify-center font-serif text-center'>See what people say about Incpass</h2>
      <div className="mx-auto max-w-2xl lg:max-w-4xl">

       
        
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-serif leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
              “I can't express how grateful I am to have found IncPass. As a young entrepreneur based in Europe, expanding my business to Canada seemed like a daunting task. However, with their expert guidance and seamless services, registering my company in Canada became a breeze.”
            </p>
          </blockquote>
          <figcaption className="mt-10">
            <img
              className="mx-auto h-10 w-10 rounded-full"
              src="/img/emma.jpeg"
              alt=""
            />
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-900">Sofia Jensen</div>
              {/* <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900"> */}
                {/* <circle cx={1} cy={1} r={1} /> */}
              {/* </svg> */}
              <div className="text-gray-600"></div>
            </div>
          </figcaption>
        </figure>
      </div>
    </section>
  
  
  
  
  
  
  </>
   
  )
}

export default Testimonial