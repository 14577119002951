import React from 'react'
import { NavLink } from 'react-router-dom'

function BlogContainer() {
  return (<>
<div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col">
    <div class="card h-100">
      <img src="/img/bc.jpg" class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Registering A Business In British Columbia</h3>
        <p class="card-text">Incorporating a company in British Columbia (BC) is a strategic move for entrepreneurs looking to establish a strong foothold in the Canadian market. </p>

        <NavLink to={"/Registering-A-Business-In-British-Columbia"}><button class="btn btn-success mt-3">Read More </button></NavLink>
        
      </div>

  
      
    </div>
  </div>
  



  <div class="col">
    <div class="card h-100">
      <img src="/img/canada.jpg" class="card-img-top" alt="..."/>
      <div class="card-body">
        <h3 class="card-title">Registering A Business In Canada</h3>
        <p class="card-text">Incorporating a company in Canada is a strategic move for entrepreneurs looking to establish a strong foothold in the Canadian market. </p>

        <NavLink to={"/Registering-A-Business-In-Canada"}><button class="btn btn-success mt-3">Read More </button></NavLink>
        
      </div>

  
      
    </div>
  </div>





 
  
</div>

  
  </>

  )
}

export default BlogContainer