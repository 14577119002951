import React from "react";
import "../tail.css";

import { NavLink } from "react-router-dom";

function Header() {
  return (
    <>
     <section className="relative isolate overflow-hidden bg-white px-6 py-4 sm:py-32 lg:px-8">
      <div class="container  text-center incHeader">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#86df46] to-[#1da847] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>


       


        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.green.500),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
        <div class="row align-items-start">

      


          <div class="col mt-5 mb-5">

          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            
              <a href="https://incpass.ca/start" className="font-semibold text-green-600">
                <span className="absolute inset-0" aria-hidden="true" />
                Start Your Business From Anywhere<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>

            <h1>
              {" "}
              Simplify, Incorporate, Succeed <br></br> With Incpass.
              <button className="btn  btnCanada">CA</button>{" "}
            </h1>

            <p className="incPara">
              Register, incorporate, name check, local Canadian Office, and
              more—all in one place.
            </p>


            <NavLink to="/start">
              <button type="button" class="btn btn-dark incHeaderBtn">
                Get Started
              </button>
            </NavLink>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#29cd83] to-[#567b4e] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

        
        </div>
     
      </div>

      {/* <img src="/img/incheader.webp" class="card-img-top" alt="company registration in canada" /> */}





      </section>

      
    </>
  );
}

export default Header;
