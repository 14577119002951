import React from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import "../tail.css";



function Canada() {
    return (<>


        <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="lg:max-w-lg">
                            <p className="text-base font-semibold leading-7 text-green-600">Canada</p>
                            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Register A Company In Canada

                            </h1>
                            <p className="mt-6 text-xl leading-8 text-gray-700">

                                Are you an entrepreneur who wants to set up a business in Canada? In the vast landscape of business opportunities, Canada is unique as a shining example of innovation and enterprise. Possessing a varied range of markets, a stable economy, and an encouraging business climate, it's no wonder why entrepreneurs worldwide want to register a company in Canada. However, as a business owner, you must be wondering how you can easily register your business in Canada. 
                            </p>
                        </div>
                    </div>
                </div>
                <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                    <img
                        className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        src="/img/canada.jpg"
                        alt=""
                    />
                </div>
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                    <div className="lg:pr-4">
                        <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">

<p>With IncPass, registering your business in Canada becomes a seamless and efficient process, accessible from anywhere and at any time. IncPass streamlines the complexities of business registration, empowering business owners to register their ventures with ease and convenience.
                                If you're considering business registration in Canada, this guide will walk you through the process of registering a company in Canada, incorporating key considerations and essential steps to ensure a smooth journey.
</p>
                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Why Register Your Business in Canada?
                            </h2>
                            <p>There are numerous advantages associated with business registration in Canada that underscore its appeal as a prime destination for entrepreneurs. Below, we outline a select few of these benefits, illuminating why Canada stands out as an optimal choice for business registration.
                            </p>
                            <ul role="list" className="mt-8 space-y-8 text-gray-600">
                                <li className="flex gap-x-3">
                                    
                                    <span>
                                        <strong className="font-semibold text-gray-900">Stable Economy:</strong> Canada's stable and reliable economy makes it a global magnet for business investment. The variety of industries, from tech and finance to manufacturing and resources, ensures stability and growth across the board.


                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <span>
                                        <strong className="font-semibold text-gray-900">Access to Global Markets:</strong>Nestled between major continents, Canada acts as a bridge to international markets. Free trade agreements and a reputation for trustworthiness open doors for Canadian-registered businesses to expand and trade globally.


                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <span>
                                        <strong className="font-semibold text-gray-900">Thriving Ecosystem:  </strong>  Canada is a haven for businesses with its pro-business policies, clear regulations, and strong legal system. This supportive environment fosters innovation, entrepreneurial spirit, and investment, making it a breeze to launch, run, and grow your company.

                                    </span>
                                </li>
                            </ul>
                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Which Incorporation Route to Choose: Federal or Provincial?
                            </h2>
                            <p className="mt-6">
                                Selecting between federal and provincial incorporation is a significant choice made throughout the process of business registration in Canada. Federal incorporation grants you nationwide recognition and protection, allowing you to conduct business in any province or territory. On the other hand, provincial incorporation restricts your business operations to a specific province but may offer certain advantages such as lower incorporation costs and simplified regulatory requirements. Your choice depends on your business scope, expansion plans, and regulatory preferences.


                            </p>

                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">How to Register a Business in Canada?

                            </h2>
                            <p className="mt-6">
                                1. Business Name Reservation
                                <br></br>
                                The most crucial phase for company registration in Canada is to choose a name for your business in Canada. Before registering your business, ascertain whether the name you have in mind is both legal and acceptable in Canada. Conduct a name search through the Corporate Registry to check availability and reserve your business name.
                                <br></br>

                                2. Register the Business

                                <br></br>

                                Once your name is approved, proceed with the process of company registration in Canada. Prepare the necessary documents and submit them to the appropriate government authority, either federal or provincial, along with the required fees.

                                <br></br>

                                3. Acquire Business Registration Number

                                <br></br>


                                Upon successful registration, you will receive a Business Registration Number (BRN), which identifies your business for tax purposes and other government interactions.

                                <br></br>

                                4. Acquire the Certificate of Incorporation and Notice of Articles

                                <br></br>

                                Following registration, you will obtain a Certificate of Incorporation and Notice of Articles attesting to the structure and legal standing of your company.

                            </p>

                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Post-Incorporation Obligations for Registering a Business in Canada</h2>

                            <h3 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Register for GST/HST </h3>


                            <p className="mt-6">
                                If your business exceeds the GST/HST threshold, you must register for Goods and Services Tax/Harmonized Sales Tax (GST/HST) to collect and remit taxes on eligible goods and services.</p>

                            <h3 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Annual Compliances </h3>
                            <p className="mt-6">
                                As a registered business, you're required to meet annual compliance obligations, including filing annual returns, updating corporate records, and paying applicable taxes and fees.
                                Effortlessly navigate the intricate process of registering a company in Canada with the seamless support of Incpass. Our all-inclusive platform streamlines every procedure, ensuring a smooth and efficient registration procedure.

                            </p>

                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Types of Structures You Can Register in Canada
                            </h2>
                            <p className="mt-6">
                                As a registered business, you're required to meet annual compliance obligations, including filing annual returns, updating corporate records, and paying applicable taxes and fees.
                                Effortlessly navigate the intricate process of registering a company in Canada with the seamless support of Incpass. Our all-inclusive platform streamlines every procedure, ensuring a smooth and efficient registration procedure.

                            </p>

                            <ul role="list" className="mt-8 space-y-8 text-gray-600">
                                <li className="flex gap-x-3">
                                    <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Sole Proprietorship: </strong> A sole proprietorship is a form of business that is managed and controlled by one individual.



                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Partnership:</strong>A partnership is established when two or more people or entities share profits and liabilities.



                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                    <span>
                                        <strong className="font-semibold text-gray-900">Corporation:  </strong>  A corporation is a distinct legal structure managed by shareholders, providing limited liability protection as well as tax benefits.


                                    </span>
                                </li>
                            </ul>



                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Duration to Set Up a Company in Canada

                            </h2>
                            <p className="mt-6">
                                The time it takes to register a company in Canada depends on various factors, including the chosen business structure, jurisdiction, and processing speed. Typically, standard incorporation through Incpass may take around 21 to 25 days whereas rush incorporation services can expedite the process, completing business registration in as little as seven working days.

                            </p>


                            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Duration to Set Up a Company in Canada

                            </h2>
                            <p className="mt-6">
                                The time it takes to register a company in Canada depends on various factors, including the chosen business structure, jurisdiction, and processing speed. Typically, standard incorporation through Incpass may take around 21 to 25 days whereas rush incorporation services can expedite the process, completing business registration in as little as seven working days.

                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>

    )
}

export default Canada