import React, { Component } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types';
export class BookItems extends Component {
   render() {
       const { title } = this.props.book;
       return (
           <div>
              <h2>{title.rendered}</h2>
           </div>
       )
   }
}
export default BookItems