import React from 'react'

function CartPrice() {
    return (<>


<div className="relative isolate overflow-hidden bg-gray-900 py-8 sm:py-8 lg:py-10">


            <main class="relative min-h-screen flex flex-col justify-center overflow-hidden ">

                <div className='justify-center text-center align-content-center mt-4'>

                    <div className="justify-center flex font-bold font-serif text-5xl mt-[20px] text-white">
                        Let’s Get Your Business Started
                    </div>
                    <div className="flex text-white text-xl mt-[10px] justify-center text-center">
                        Welcome, get your business going in just a few clicks. We recommend
                        the right products and services to help set you up for success.
                    </div>



                </div>





                <div class="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">

                    <div class="max-w-sm mx-auto grid gap-6 lg:grid-cols-2 items-start lg:max-w-[728px]">
                        <div class="h-full">
                            <div class="relative flex flex-col h-full p-6 pb-10 rounded-2xl bg-slate-100 border border-slate-200 shadow shadow-slate-950/5">
                                <div class="h-8" aria-hidden="true"></div>
                                <div class="mb-5">
                                    <div class="inline-flex items-baseline mb-2">
                                        <span class="text-slate-900 font-bold text-3xl">Resident</span>

                                    </div>


                                </div>
                                <div class="text-slate-900 text-sm font-medium mb-4">Register your company with the relevant province without going through the KYC and legalization procedure.</div>
                                <a href="https://incpass.ca/pricedata" class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-emerald-400  px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-zinc-950 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150" >
                                    Start Now
                                </a>

                                <img src='/img/S.png' class="mt-4" />

                            </div>
                        </div>

                        <div class="h-full">
                            <div class="relative flex flex-col h-full p-6 pb-10 rounded-2xl bg-slate-900 border border-slate-900 shadow shadow-slate-950/5">
                                <div class="absolute top-0 right-0 mr-6 -mt-4">
                                    <div class="inline-flex items-center text-xs font-semibold py-1.5 px-3 bg-emerald-500 text-white rounded-full shadow-sm shadow-slate-950/5">Most Popular</div>
                                </div>
                                <div class="h-8" aria-hidden="true">
                                    <svg class="drop-shadow-[0_0_8px_rgba(19, 224, 63, 0.7)]" xmlns="http://www.w3.org/2000/svg" width="24" height="26">
                                        <defs>
                                            <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
                                                <stop offset="0%" stop-color="#24fb36" />
                                                <stop offset="100%" stop-color="#0bf536" />
                                            </linearGradient>
                                        </defs>
                                        <path fill="url(#a)" fill-rule="evenodd" d="M15 0 0 16h11L9 26l15-16H13z" />
                                    </svg>
                                </div>
                                <div class="mb-5">

                                    <div class="inline-flex items-baseline mb-2">
                                        <span class="text-slate-200 font-bold text-3xl">Non-Resident</span>

                                    </div>


                                </div>
                                <div class="text-slate-200 text-sm font-medium mb-4">Unlock Canada's business potential for non-residents with tax incentives, and a balanced cost of living.</div>


                                <a href="https://incpass.ca/pricedata" class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-emerald-400 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-zinc-950 focus-visible:outline-none focus-visible:ring focus-visible:ring-slate-600 transition-colors duration-150" >
                                    Start Now
                                </a>
                                <img src='/img/R.png' class="mt-4" />

                            </div>

                        </div>


              




                    </div>


           

                </div>
            </main>

            <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffffff] to-[#9d9d9d] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>


      




    </>

    )
}

export default CartPrice