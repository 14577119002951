import React from 'react';

function Video() {
  return (



<div className="relative overflow-hidden bg-white ">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <div className="sm:max-w-lg">
            <h2 className="text-3xl font-bold font-serif tracking-tight text-gray-900 sm:text-6xl">
            Start your dream business           
            
             </h2>
            <p className="mt-4 text-xl text-gray-500">
            Are you ready to turn your entrepreneurial dreams into reality? Whether you're a local resident or an international investor, launching a company in Canada can be a rewarding journey.
            </p>
          </div>
          <div>
            <div className="mt-10">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className=" lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="h-64 w-70 rounded-lg sm:opacity-0 lg:opacity-100">
                      <a href="/img/Male.mp4">
                         <img
                          src="/img/v2.webp"
                          alt=""
                          className="rounded h-full w-full object-cover object-center"
                          
                        />
                        </a>
                      </div>
                  
                    </div>
                
                 
                  </div>
                </div>
              </div>

           
            </div>
          </div>
        </div>
      </div>
    </div>











    
    // <div className="container cont mt-5">
    //   <div
    //     id="carouselExampleIndicators"
    //     className="carousel slide img-video-size"
    //   >
    //     <div className="carousel-indicators">
    //       <button
    //         type="button"
    //         data-bs-target="#carouselExampleIndicators"
    //         data-bs-slide-to="0"
    //         className="active"
    //         aria-current="true"
    //         aria-label="Slide 1"
    //       ></button>
    //       <button
    //         type="button"
    //         data-bs-target="#carouselExampleIndicators"
    //         data-bs-slide-to="1"
    //         aria-label="Slide 2"
    //       ></button>
    //     </div>
    //     <div className="carousel-inner">
    //       <div className="carousel-item active">
    //         <a href="/img/Female.mp4">
    //           <img
    //             src="/img/v1.webp"
    //             className="d-block w-100 rounded img-video"
    //             alt="..."
    //           />
    //         </a>
    //       </div>
    //       <div className="carousel-item">
    //         <a href="/img/Male.mp4">
    //           <img
    //             src="/img/v2.webp"
    //             className="d-block w-100 rounded img-video"
    //             alt="..."
    //           />
    //         </a>
    //       </div>
    //     </div>
    //     <button
    //       className="carousel-control-prev"
    //       type="button"
    //       data-bs-target="#carouselExampleIndicators"
    //       data-bs-slide="prev"
    //     >
    //       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    //       <span className="visually-hidden">Previous</span>
    //     </button>
    //     <button
    //       className="carousel-control-next"
    //       type="button"
    //       data-bs-target="#carouselExampleIndicators"
    //       data-bs-slide="next"
    //     >
    //       <span className="carousel-control-next-icon" aria-hidden="true"></span>
    //       <span className="visually-hidden">Next</span>
    //     </button>
    //   </div>
    // </div>
  );
}

export default Video;
