import React from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import "../tail.css";



function BC() {
  return (<>
    

    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-green-600">British Columbia</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Registering A Business In British Columbia
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Incorporating a company in British Columbia (BC) is a strategic move for entrepreneurs looking to establish a strong foothold in the Canadian market. Given its robust economy, welcoming business climate, and ample opportunities, BC offers an ideal landscape for budding enterprises.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="/img/ea.jpg"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                If you're considering embarking on this journey, understanding the process and nuances of British Columbia company registration is paramount. In this comprehensive guide, we'll walk you through the essentials, from why you should incorporate in British Columbia to the step-by-step process and types of businesses available.

              </p>

              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Why Incorporate a Company in BC?</h2>
              <p>Business owners of all sizes will find British Columbia to be a fertile ground. Here are a few reasons why incorporating a company in BC might be the perfect fit for you:</p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Stable Economy:</strong> LBC boasts a robust and diversified economy, providing a secure foundation for your venture.

                  </span>
                </li>
                <li className="flex gap-x-3">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Innovation Hub:</strong> The province is a hub for innovation, fostering a supportive environment for entrepreneurs with cutting-edge ideas.

                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Government Support: </strong>  Government programs and resources are available to assist businesses at various stages of growth.

                  </span>
                </li>
              </ul>
             
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Conclusion</h2>
              <p className="mt-6">
              Establishing a company in British Columbia opens you to a world of opportunity in the country's booming economy. Entrepreneurs can make a solid name for themselves in BC's ever-changing business environment by comprehending the incorporation process, selecting the ideal firm structure, and meeting their post-incorporation responsibilities. Registering a business in British Columbia paves the way for long-term prosperity and expansion, whether it's by gaining access to tax incentives, establishing credibility, or growing operations.

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


  </>

  )
}

export default BC