import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51J6TOJDyOVzZxe5dR07FIWpJpsso7Ot3eTpfkxEuIahyqG0VVkWW6Z6P2ZA0p7W6E9ypQsB7fxN4rRU05QktbZzF00H0dAPj6K"
);

const StripePaymentWidget = ({ clientSecret }) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripePaymentWidget;
