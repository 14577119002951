import React from 'react'
import Header from './components/Header'
// import Platform from './components/Platform'
import Features from './components/Features'
// import Pricing from './components/Pricing'
import Satisfaction from './components/Satisfaction'
// import Boxprice from './components/Boxprice'
import Video from './components/Video'
import Trust from './components/Trust'
import AboutUs from './components/AboutUs'
import Testimonial from './components/Testimonial'
import CartPrice from './components/CartPrice'
import Team from './components/Team'
import Choose from './components/Choose'
import Banner from './components/Banner'



function Home() {
  return (<>
  
  
  <Header/>
  <Trust/>

  {/* <Platform/> */}
  <Choose/>
  <Video/>
  <Features/>
 
  <CartPrice/>
  <Testimonial/>
  <Banner/>

  {/* <Boxprice/> */}
  {/* <Pricing/> */}
  {/* <Satisfaction/> */}
 
  {/* <Team/> */}
  {/* <AboutUs/> */}
  
  </>
  )
}

export default Home