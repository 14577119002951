import React from 'react';
import { Helmet } from 'react-helmet';


function Contact() {
  return (<>

<Helmet>
      <title>Home Page - My React App</title>
      <meta name="description" content="Welcome to the home page of my React app" />
      {/* Additional head elements */}
    </Helmet>



  
  <div>


  <iframe aria-label='Contact Us' frameborder="0" style={{height:'500px',width:'99%',border:'none'}} src='https://forms.zohopublic.in/AdminDesk/form/IncPassContact/formperma/2H5AnvTUfSoCDeWbtNrLvnTjw5aytsdslep8t6dWdT0'></iframe>

  </div>
  
  
  
  </>
    
  )
}

export default Contact